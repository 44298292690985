// Internal Imports
import { AUTHOR, CONTENT_ITEM, TERM } from './types/entity.mjs';

// Local Variables
const active = {
  contentEntities: [],
  error: null, // eslint-disable-line unicorn/no-null -- null keeps the key visible before data is loaded into it
  loading: {
    content: false,
    more: false,
    siteData: false,
  },
  notFound: false,
  pagination: {
    currentPage: 1,
  },
};

const archiveRoute = {
  filters: {
    contentItem: {},
    term: {},
  },
  order: {
    asc: 'Oldest',
    desc: 'Newest',
    relevance: 'Relevance',
  },
  pages: {},
};

const contentGroup = {
  byId: {},
  idBySlug: {},
};

const user = {
  isLoading: false,
};

const defaultState = {
  active,
  archive: {},
  [AUTHOR]: {},
  [CONTENT_ITEM]: {},
  contentType: {},
  featureFlags: {},
  footer: {
    text: [],
  },
  info: {},
  landingPage: null, // eslint-disable-line unicorn/no-null -- null keeps the key visible before data is loaded into it
  languagePicker: {},
  languages: {},
  menu: {},
  redirect: {},
  siteOptionsError: {},
  socialMedia: {},
  statsigExperiment: {
    isInExperimentGroup: false,
    isLoading: true,
    wasQueried: false,
  },
  taxonomy: {},
  [TERM]: {},
  translations: {},
  user,
};

// Module Exports
export { active, archiveRoute, contentGroup, defaultState, user };
