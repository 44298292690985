// External Imports
import { LOCATION_CHANGE } from 'connected-react-router';

// Internal Imports
import {
  ARTICLE_LINK_CLICKED,
  ARTICLE_SHARED,
  CALL_TO_ACTION_CLICKED,
  FETCH_USER_REQUESTED,
  QUERY_STATSIG,
  RECEIVE_ERROR,
  RECEIVE_PRIMARY_CONTENT,
  RECEIVE_SITE_OPTIONS,
  RECEIVE_SITE_OPTIONS_ERROR,
  REQUEST_ARCHIVE,
  REQUEST_CONTENT_ITEM,
  REQUEST_NEXT_PAGE_FETCH_INITIATED,
  REQUEST_NEXT_PAGE,
  REQUEST_SITE_OPTIONS,
} from '../config/types/action.mjs';

// Local Functions
/**
 * Create a Flux Standard Action
 * @param  {string} type action type
 * @param  {object} data data to pass into state
 * @return {object} to be consumed by the reducer
 */
// eslint-disable-next-line unicorn/no-null -- keep null payload for starting
function createAction(type, data = null) {
  return { payload: data, type };
}

/**
 * Create a receive secondary content Redux action.
 * @param   {object} content A map of content item related types to content item collections
 * @returns {object}         Redux action
 */
function actionReceivePrimaryContent(content) {
  return createAction(RECEIVE_PRIMARY_CONTENT, content);
}

/**
 * Create a receive error Redux action.
 * @param   {Error}  error
 * @returns {object} Redux action
 */
function actionReceiveError(error) {
  return createAction(RECEIVE_ERROR, error);
}

/**
 * Create a receive site data Redux action.
 * @param   {object}   siteOptions
 * @param   {object[]} siteOptions.menu        map of menu location to menu entities
 * @param   {object[]} siteOptions.info        general site info
 * @param   {object[]} siteOptions.contentType defined content type slugs
 * @param   {object[]} siteOptions.taxonomy    defined taxonomy slugs
 * @param   {object[]} siteOptions.footer      footer data
 * @param   {object[]} siteOptions.redirect    map of paths to redirected to paths
 * @returns {object}                           Redux action
 */
function actionReceiveSiteOptions(siteOptions) {
  return createAction(RECEIVE_SITE_OPTIONS, siteOptions);
}

function actionReceiveSiteOptionsError(error) {
  return createAction(RECEIVE_SITE_OPTIONS_ERROR, error);
}

function actionRequestContentItem() {
  return createAction(REQUEST_CONTENT_ITEM);
}

function actionRequestArchive() {
  return createAction(REQUEST_ARCHIVE);
}

function actionRequestNextPage() {
  return createAction(REQUEST_NEXT_PAGE);
}

function actionRequestNextPageFetchInitiated() {
  return createAction(REQUEST_NEXT_PAGE_FETCH_INITIATED);
}

function actionRequestSiteOptions() {
  return createAction(REQUEST_SITE_OPTIONS);
}

function actionLocationChange(location) {
  return createAction(LOCATION_CHANGE, location);
}

function actionQueryStatsig(payload) {
  return createAction(QUERY_STATSIG, payload);
}

function fetchUser() {
  return createAction(FETCH_USER_REQUESTED);
}

function actionArticleLinkClicked(payload) {
  return createAction(ARTICLE_LINK_CLICKED, payload);
}

function actionArticleShared(payload) {
  return createAction(ARTICLE_SHARED, payload);
}

function actionCtaClicked(payload) {
  return createAction(CALL_TO_ACTION_CLICKED, payload);
}

// Module Exports
export {
  actionArticleLinkClicked,
  actionArticleShared,
  actionCtaClicked,
  actionLocationChange,
  actionQueryStatsig,
  actionReceiveError,
  actionReceivePrimaryContent,
  actionReceiveSiteOptions,
  actionReceiveSiteOptionsError,
  actionRequestArchive,
  actionRequestContentItem,
  actionRequestNextPage,
  actionRequestNextPageFetchInitiated,
  actionRequestSiteOptions,
  fetchUser,
};
