import { compile } from 'path-to-regexp';
import { matchPath } from 'react-router';

// Internal Imports
import { hostnameRegex, pathRegex } from '../config/pathRegex.mjs';
import {
  ENGLISH,
  ENGLISH_USA,
  FRENCH,
  GERMAN,
  ITALIAN,
  POLISH,
  PORTUGUESE,
  SPANISH,
  SWEDISH,
} from '../config/types/language.mjs';
import { checkUsEnglish } from './checkUsEnglish.mjs';
import { isNode } from './environment.mjs';
import { getRuntimeConfiguration } from './getRuntimeConfiguration.mjs';
import { LANGUAGE_EDITION } from '../config/types/path.mjs';

// Local Variables
const isAbsoluteRegex = /^(?:[a-z]+:)?\/\//;
const isMailtoRegex = /^mailto:/;
const stagingDomainExtension = 'cn';

// Local Functions
const isAbsolute = (url) => {
  if (typeof url !== 'string') {
    return false;
  }

  const matches = url.match(isAbsoluteRegex);
  return Boolean(matches) && matches[0].length > 0;
};

const isMailto = (url) => isMailtoRegex.test(url);

const isLocalHost = (hostname) => hostname.startsWith('localhost');

const isBabbelHostname = (hostname) => hostnameRegex.test(hostname);

const isTrackingDisabled =
  !isNode() && new URLSearchParams(window.location.search).has('disableTracking');

const getLanguageHostname = (hostname, languageCode) => {
  if (!isBabbelHostname(hostname)) {
    return hostname;
  }

  const domainSuffix = hostname.match(hostnameRegex)[1];
  switch (languageCode) {
    case FRENCH:
      return `fr.${domainSuffix}`;
    case GERMAN:
      return `de.${domainSuffix}`;
    case ITALIAN:
      return `it.${domainSuffix}`;
    case POLISH:
      return `pl.${domainSuffix}`;
    case PORTUGUESE:
      return `pt.${domainSuffix}`;
    case SPANISH:
      return `es.${domainSuffix}`;
    case SWEDISH:
      return `se.${domainSuffix}`;
    default:
      return `www.${domainSuffix}`;
  }
};

const getLanguageCodeFromPath = (path) => {
  const match = matchPath(path, {
    exact: false,
    path: pathRegex[LANGUAGE_EDITION],
  });
  return match?.params?.language ?? '';
};

const getApigatewayBaseUrl = () =>
  getRuntimeConfiguration('APIGATEWAY_BASE_URL') || 'https://api.babbel-staging.io/gamma';

const getGoBaseUrl = () => getRuntimeConfiguration('GO_BASE_URL') || 'https://go.babbel.cn';

const getMyBaseUrl = () => getRuntimeConfiguration('MY_BASE_URL') || 'https://my.babbel.cn';

const getAwsSessionEndpoint = () =>
  getRuntimeConfiguration('AWS_SESSION_ENDPOINT') || '/aws_session.json';

const getAwsSessionMethod = () => getRuntimeConfiguration('AWS_SESSION_METHOD') || 'GET';

const buildShortcodeUrl = (shortcode, suffix = 'default.gif') =>
  `${getGoBaseUrl()}/${shortcode}/${suffix}`;

// Can be used for client- or server-side rendering
const getHostname = () => (isNode() ? process.env.REQUEST_HOSTNAME : window.location.hostname);
const getProtocol = () => (isNode() ? process.env.PROTOCOL ?? 'https:' : window.location.protocol);
const getCurrentEditionBaseUrl = () => `${getProtocol()}//${getHostname()}`;

const hostIsStaging = (hostname) =>
  hostname.endsWith(stagingDomainExtension) || isLocalHost(hostname);

const currentHostIsStaging = () => hostIsStaging(getHostname());

const getCurrentDomainExtension = () => (currentHostIsStaging() ? stagingDomainExtension : 'com');

const getBabbelBaseUrl = () => `https://www.babbel.${getCurrentDomainExtension()}`;

const prependEditionHostname = (languageCode, path = '/') => {
  const currentHostname = getHostname();
  const newHostname = getLanguageHostname(currentHostname, languageCode);

  // Use a relative path only when intending to stay within the current hostname so
  // this app remains a SPA instead of doing full page loads
  const url = newHostname === currentHostname ? path : `//${newHostname}${path}`;

  return url;
};

const buildCanonicalLink = (currentEdition, relativePath) =>
  `https://${getLanguageHostname('www.babbel.com', currentEdition)}${relativePath}`;

// Input: Given the currentEdition (i.e., en, es, fr)
// Output: provide absolute homepageUrl
// Context: use in NotFound component to redirect the 404.
// Note: currently magazine only offered in USA English, no redirect to UK site
const getBabbelHomepageUrl = (currentEdition) => {
  switch (currentEdition) {
    case FRENCH:
      return 'https://fr.babbel.com/?locale=fr';
    case GERMAN:
      return 'https://de.babbel.com/?locale=de';
    case ITALIAN:
      return 'https://it.babbel.com/?locale=it';
    case POLISH:
      return 'https://pl.babbel.com/?locale=pl';
    case PORTUGUESE:
      return 'https://pt.babbel.com/?locale=pt';
    case SPANISH:
      return 'https://es.babbel.com/?locale=es';
    case SWEDISH:
      return 'https://se.babbel.com/?locale=sv';
    default:
      return 'https://www.babbel.com/';
  }
};

// Input: Given the currentEdition (i.e., en, es, fr)
// Output: provide absolute rss url
// Context: use in SocialLinks component to generate the correct link for the RSS icon
const getBabbelRSSUrl = (currentEdition) => {
  const rssUrlPrefix = 'https://rss.babbel.news/feed/?post_type=';
  switch (currentEdition) {
    case FRENCH:
      return `${rssUrlPrefix}french_edition`;
    case GERMAN:
      return `${rssUrlPrefix}german_edition`;
    case ITALIAN:
      return `${rssUrlPrefix}italian_edition`;
    case POLISH:
      return `${rssUrlPrefix}polish_edition`;
    case PORTUGUESE:
      return `${rssUrlPrefix}portuguese_edition`;
    case SPANISH:
      return `${rssUrlPrefix}spanish_edition`;
    case SWEDISH:
      return `${rssUrlPrefix}swedish_edition`;
    default:
      return `${rssUrlPrefix}english_edition`;
  }
};

const getInstagramUrl = (currentEdition) => {
  const instagramUrlPrefix = 'https://www.instagram.com/';
  switch (currentEdition) {
    case ENGLISH:
    case ENGLISH_USA:
      return `${instagramUrlPrefix}babbel/`;
    case FRENCH:
      return `${instagramUrlPrefix}babbel_fr/`;
    case GERMAN:
      return `${instagramUrlPrefix}babbel_de/`;
    case PORTUGUESE:
      return `${instagramUrlPrefix}babbel_pt/`;
    case SPANISH:
      return `${instagramUrlPrefix}babbel_es/`;
    case ITALIAN:
      return `${instagramUrlPrefix}babbel_it/`;
    default:
      return `${instagramUrlPrefix}babbel`;
  }
};

const getSocialLinks = (currentEdition) => ({
  facebook: 'https://www.facebook.com/babbel.languages',
  instagram: getInstagramUrl(currentEdition),
  linkedin: 'https://www.linkedin.com/company/babbel-com',
  twitter: 'https://twitter.com/babbel',
  youtube: 'https://www.youtube.com/user/LessonNine',
});

const buildMagazineHomepageLink = (rawLanguageCode) => {
  const convertedLanguageCode = checkUsEnglish(rawLanguageCode);
  const toLanguagePath = compile(pathRegex[LANGUAGE_EDITION]);
  const path = toLanguagePath({
    language: convertedLanguageCode,
  });

  const fullUrl = prependEditionHostname(convertedLanguageCode, path);
  return fullUrl;
};

const buildCTALink = (currentEdition) => `${getMyBaseUrl()}/${currentEdition}/onboarding/default`;

// Module Exports
export {
  buildCanonicalLink,
  buildCTALink,
  buildMagazineHomepageLink,
  buildShortcodeUrl,
  getApigatewayBaseUrl,
  getAwsSessionEndpoint,
  getAwsSessionMethod,
  getBabbelBaseUrl,
  getBabbelHomepageUrl,
  getBabbelRSSUrl,
  getCurrentEditionBaseUrl,
  getGoBaseUrl,
  getHostname,
  getInstagramUrl,
  getLanguageCodeFromPath,
  getLanguageHostname,
  getMyBaseUrl,
  getSocialLinks,
  hostIsStaging,
  isAbsolute,
  isBabbelHostname,
  isLocalHost,
  isMailto,
  isTrackingDisabled,
  prependEditionHostname,
};
