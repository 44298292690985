// External Imports
import 'lazysizes';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router';
import { pxToRem, SSRCheck, Stack, styled, Text } from '@lessonnine/design-system.lib';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import '@lessonnine/design-tokens.lib/dist/css/new_fonts.css';

// Internal Imports
import { buildShortcodeUrl } from '../../utils/url.mjs';
import { containerMap } from '../../config/types/content/containerMap.mjs';
import { ContentEmbedProvider } from '../ContentEmbedProvider/ContentEmbedProvider.jsx';
import { createSelectTranslation } from '../../selectors/createSelectTranslation.mjs';
import { fetchUser as fetchUserAction } from '../../actions/index.mjs';
import { Footer } from '../Footer/Footer.jsx';
import { selectEdition } from '../../selectors/selectEdition.mjs';
import { getFacebookLocale } from '../../utils/metadata.mjs';
import { selectTitle } from '../../selectors/selectTitle.mjs';
import { selectIs404 } from '../../selectors/selectIs404.mjs';
import { selectIsInProductMagazine } from '../../selectors/selectIsInProductMagazine.mjs';
import { selectLocale } from '../../selectors/selectLocale.mjs';
import { selectMatch } from '../../selectors/selectMatch.mjs';
import { selectPageTrackingData } from '../../selectors/selectPageTrackingData.mjs';
import { selectPathMap } from '../../selectors/selectPathMap.mjs';
import { getSessionStartedStatus } from '../../utils/getSessionStartedStatus.mjs';
import { Header } from '../Header/Header.jsx';
import { isInMobileApp } from '../../utils/isInMobileApp.mjs';
import { isNode } from '../../utils/environment.mjs';
import { LANGUAGES } from '../../config/types/prop.mjs';
import { MarketingTracker } from '../MarketingTracker/MarketingTracker.jsx';
import { MastheadLanguagePicker } from '../LanguagePicker/MastheadLanguagePicker.jsx';
import { NotFound } from '../NotFound/NotFound.jsx';
import { options } from '../../config/reactRedux.mjs';
import { PageShownTracking } from '../PageShownTracking/PageShownTracking.jsx';
import { pathTypeOrder } from '../../config/types/path.mjs';
import { ShortcodePixel } from '../Tracking/ShortcodePixel.jsx';
import { SpeedcurveRum } from '../SpeedcurveRum/SpeedcurveRum.jsx';
import { trackPageEvent } from '../../utils/tracking.mjs';
import {
  languageCodeEnglishFullNames,
  languageCodePostTypeWithEnglishMap,
} from '../../config/types/language.mjs';
import { selectHasWordPressFetchError } from '../../selectors/selectHasWordPressFetchError.mjs';
import { breakpoints } from '../../config/css/breakpoints.mjs';
import { siteGutter, siteMaxWidth } from '../../config/css/ui.mjs';
import { CssReset } from './CssReset.jsx';
import { I18n } from '../I18n/I18n.jsx';
import { selectLoadingContent } from '../../selectors/selectLoading.mjs';
import { BackgroundColor } from '../BackgroundColor/BackgroundColor.jsx';
import { getRuntimeConfiguration } from '../../utils/getRuntimeConfiguration.mjs';
import { getTrackingUuid } from '../../utils/getTrackingUuid.mjs';

// Local Variables
const Container = styled.div`
  min-height: 100vh;
`;

const MainLayout = styled(Stack)`
  @media ${breakpoints.tabletPortrait.min} {
    max-width: ${pxToRem(siteMaxWidth)};
    overflow: hidden;
  }
`;

// Component Definition
function AppBase({
  facebookLocale,
  is404,
  isContentLoading,
  locale,
  location,
  magazineEditionLanguage,
  pathMap,
  getTranslation,
  fetchUser,
  isInProductMagazine,
  pageTrackingData,
  hasError,
}) {
  const shortcode = queryString.parse(location.search).bsc;
  const languageEnglishName = languageCodeEnglishFullNames[locale];
  const rssEdition = languageCodePostTypeWithEnglishMap[locale];
  const [enablePageShownTracking, setEnablePageShownTracking] = useState(false);
  const hasSessionStarted = useRef(getSessionStartedStatus());
  const trackingUuid = getTrackingUuid();
  const { data, isLoading } = pageTrackingData;
  const lastValidTrackingDataRef = useRef();

  const { client } = useClientAsyncInit(
    getRuntimeConfiguration('STATSIG_CLIENT_API_KEY'),
    { customIDs: { tracking_uuid: trackingUuid }, locale },
    { environment: { tier: getRuntimeConfiguration('ENVIRONMENT') } },
  );

  useEffect(() => {
    if (!is404) {
      lastValidTrackingDataRef.current = data;
    }

    const unloadingEventType = isInMobileApp ? 'beforeunload' : 'pagehide';
    const removeUserSession = () => {
      if (lastValidTrackingDataRef.current) {
        trackPageEvent({
          name: 'magazine_page:session_ended',
          version: 2,
          ...lastValidTrackingDataRef.current,
        });
      }
      window.sessionStorage.removeItem('session_started');
    };

    window.addEventListener(unloadingEventType, removeUserSession);

    return () => {
      window.removeEventListener(unloadingEventType, removeUserSession);
    };
  }, [data, is404]);

  useEffect(() => {
    if (!hasSessionStarted.current && !isLoading && !is404) {
      trackPageEvent({
        name: 'magazine_page:session_started',
        version: 2,
        ...data,
      });
      window.sessionStorage.setItem('session_started', Date.now());
      hasSessionStarted.current = true;
    }
  }, [data, is404, isLoading]);

  useEffect(() => {
    fetchUser();
    setEnablePageShownTracking(true);
  }, [fetchUser]);

  return (
    <StatsigProvider client={client}>
      <Container>
        <SSRCheck />
        <CssReset />
        <BackgroundColor />
        <MarketingTracker
          isContentLoading={isContentLoading}
          location={location}
          magazineEditionLanguage={magazineEditionLanguage}
        />
        <SpeedcurveRum />
        {!isNode() && shortcode && <ShortcodePixel url={buildShortcodeUrl(shortcode)} />}
        <Helmet>
          <title>{getTranslation('meta-title', 'Babbel Magazine')}</title>
          <meta name="babbel:locale" content={magazineEditionLanguage} />
          <meta name="babbel:facebook:locale" content={facebookLocale} />
          <meta property="og:site_name" content={getTranslation('meta-title', 'Babbel Magazine')} />
          <link
            rel="alternate"
            type="application/rss+xml"
            title={`Babbel Magazine - ${languageEnglishName} Edition`}
            href={`https://cms.babbel.news/feed/?post_type=${rssEdition}`}
          />
        </Helmet>
        {enablePageShownTracking && !is404 && <PageShownTracking />}
        <Header />
        {!isInProductMagazine && <MastheadLanguagePicker />}
        <Stack as="main" alignItems="center" padding={[isInProductMagazine ? 10 : 30, 0, 60]}>
          <MainLayout padding={[0, siteGutter]}>
            <ContentEmbedProvider>
              {hasError && (
                /* TODO [redesign]: We should get rid of the Stack when we change typography, but for now because of the Text capsizing, it's required for the error message not to be cropped on Desktop */
                <Stack padding={[10, 0]}>
                  <Text fontSize="big" fontWeight="bold">
                    <I18n
                      field="unexpected-error-message"
                      defaultText="An unexpected error occured, you can try refreshing the page."
                    />
                  </Text>
                </Stack>
              )}
              {!hasError && (
                <Switch>
                  {is404 && <Route component={NotFound} />}
                  {!is404 &&
                    pathTypeOrder.map(
                      (pathType) =>
                        pathMap[pathType] && (
                          <Route
                            exact
                            path={pathMap[pathType]}
                            component={containerMap[pathType]}
                            key={pathType}
                          />
                        ),
                    )}
                </Switch>
              )}
            </ContentEmbedProvider>
          </MainLayout>
        </Stack>
        {!isInProductMagazine && <Footer />}
      </Container>
    </StatsigProvider>
  );
}

AppBase.propTypes = {
  facebookLocale: PropTypes.string.isRequired,
  fetchUser: PropTypes.func.isRequired,
  getTranslation: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  is404: PropTypes.bool.isRequired,
  isContentLoading: PropTypes.bool.isRequired,
  isInProductMagazine: PropTypes.bool.isRequired,
  locale: LANGUAGES.isRequired,
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types -- use a TypeScript type eventually
  magazineEditionLanguage: PropTypes.string.isRequired,
  pageTrackingData: PropTypes.shape({
    data: PropTypes.object, // eslint-disable-line react/forbid-prop-types -- dynamically built
    isLoading: PropTypes.bool,
  }).isRequired,
  pathMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types -- dynamically built
};

const mapStateToProps = (state) => {
  const isContentLoading = selectLoadingContent(state);
  const pathMatch = selectMatch(state);
  const locale = selectLocale(state);
  const currentEdition = selectEdition(state);
  const getTranslation = createSelectTranslation(state);

  return {
    facebookLocale: getFacebookLocale(locale),
    getTranslation,
    hasError: selectHasWordPressFetchError(state),
    is404: selectIs404(state),
    isContentLoading,
    isInProductMagazine: selectIsInProductMagazine(state),
    locale,
    magazineEditionLanguage: currentEdition,
    pageTrackingData: selectPageTrackingData(state),
    pathMap: selectPathMap(state),
    pathMatch,
    title: selectTitle(state),
  };
};

const mapDispatchToProps = {
  fetchUser: fetchUserAction,
};

const withRedux = connect(mapStateToProps, mapDispatchToProps, undefined, options);
// withRouter HoC fixes Redux and router state becoming out of sync.
// See: https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/redux.md
const App = withRouter(withRedux(AppBase));

// Module Exports
export { App };
